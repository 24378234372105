import React from 'react';
import {
    addDays,
    addMonths,
    format,
    lastDayOfMonth,
    startOfMonth
} from 'date-fns';
import logoSmall from '../../../assets/images/calendar.svg';
import leftArrow from '../../../assets/images/leftArrow.svg';
import rightArrow from '../../../assets/images/rightArrow.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { nb } from 'date-fns/locale';
import styles from './AppointmentCalendar.module.css';
import { getAvailableDates } from '../../../api/index';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const AppointmentCalendar = ({ ...props }) => {

    const { t } = props;

    const startDate = new Date();
    const endDate = addDays(startDate, 90);
    const [availableDates, setAvailableDates] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [activeDates, setActiveDates] = useState();
    const [pgStartDate, setPageStartDate] = useState();

    const selectedStyle = { "backgroundColor": "#A0EBDA80","cursor":"pointer","border":"none", "borderRadius": "10px", /* "boxShadow": " 0px 9px 20px rgb(255, 174, 145)", */"fontFamily":"HelveticaNeue !important", "fontStyle": "italic", "fontWeight": "bold", "textAlign": "center" };
    const availableStyle = {  "backgroundColor": "transparent", "opacity": "1","cursor":"pointer" };
    const disabledStyle = { "pointerEvents": "none", "boxShadow": "none" ,"opacity":"0.4"};


    const today = new Date();
    useEffect(() => {
        let end = moment(today).add(6, 'd').format('YYYY-MM-DD');
        setPageStartDate(end);
        /* let start = moment(today).add(1, 'd').format('YYYY-MM-DD');
        
        console.log(props.doctorInfo) */
        const getDates = async () => {
            //const response = await getAvailableDates(props.service_id.id, start, end);
            //if (response)
                setAvailableDates(props.doctorInfo);   
        }
        getDates();
    }, [props.doctorInfo])


    useEffect(() => {
        setActiveDates();
        setSelectedDate();
        if (props.selectedDoc && availableDates) {
            //Filter dates where doctors have slots and store into active dates
            const doctorDates = availableDates.filter((date) => date.resources.length > 0 && date.resources.some(k => k.slots.length > 0 && k.id === props.selectedDoc.id));
            if (doctorDates.length > 0) {
                setActiveDates(doctorDates);  
            }
        }
    }, [props.selectedDoc, availableDates])

    const convertDate = (f) => {
        //convert to DD-MM-YYYY format
        let d = ('0' + f.getDate()).slice(-2);
        let m = ("0" + (f.getMonth() + 1)).slice(-2);
        let y = f.getFullYear();

        let fullDate = d + "-" + (m) + "-" + y;
        return fullDate;
    }

    const fetchMoredays = async () => {
        //Fetch dated for next 7 days
        let s = moment(pgStartDate).add(1, 'd').format('YYYY-MM-DD');
        let e = moment(pgStartDate).add(7, 'd').format('YYYY-MM-DD');
        setPageStartDate(e)
        const response = await getAvailableDates(props.service_id.id, s, e);
        const d = availableDates.concat(response);
        setAvailableDates(d);

    }

    const getTimeSlots = (doctorObject, day) => {
        //Get slots on a date
        let timeSlots = [];
        let availableTimeSlots = doctorObject.resources.filter(doc=>doc.id === props.selectedDoc.id);
            availableTimeSlots[0].slots.map((t) => {
            timeSlots.push(t)
        })
        return timeSlots;
    }

    const getStyles = (day, month) => {
        if (convertDate(day) === selectedDate) {
            return selectedStyle;
        }
        else if (checkDoctorAvailability(convertDate(day))) {
            return availableStyle
        }
        return disabledStyle;
    };

    const checkDoctorAvailability = (day) => {
        if (activeDates) {
            const a = activeDates.some((d) => d.date === day)
            return a;
        }
    }


    const scrollNextWeek = () => {
        const e = document.getElementById("scroll");
        const width = e.getBoundingClientRect().width; 
        e.scrollLeft += width - 0;
        fetchMoredays()
    }

    const scrollPrevWeek = () => {
        const e = document.getElementById("scroll");
        const width = e.getBoundingClientRect().width;
        e.scrollLeft -= width - 0;
    }

    const dateSelection = (day) => {
        //When user selects a date get time slots and send back
        setSelectedDate(convertDate(day));
        props.selectedDate(day);
        const getDate = activeDates.filter((d) => d.date === convertDate(day))
        const slots = getTimeSlots(getDate[0],)
        if (slots) {
            props.timeSlots(slots);
        }
    }

    function renderDays() {
        let start, end;
        const dayFormat = "eee";
        const dateFormat = "d";
        let days = [];
        let months = [];
        for (let i = 0; i <= 3; i++) {
            const month = startOfMonth(addMonths(startDate, i));
            //const year = getYear(startDate);
            start = i === 0 ? Number(format(addDays(startDate, 0), dateFormat))-1 : 0;
            end = i === 3 ? Number(format(endDate, "d")) : Number(format(lastDayOfMonth(month), "d"));
            for (let j = start; j < end; j++) {
                
                days.push(
                    <div className={styles.subDiv1} key={addDays(month, j)} style={getStyles(addDays(month, j), month)} onClick={()=>dateSelection(addDays(month, j))} >
                        <div className={styles.smallDiv1}>{format((addDays(month, j)), dayFormat,{locale:nb})}</div>
                        <div className={styles.smallDiv1}>{format((addDays(month, j)), dateFormat, { locale: nb })}</div>
                    </div>
                )

            }
            

            months.push(
                <div key={i} className={styles.monthDiv} >
                    <div className={styles.monthLabelDiv}>
                        <div className={styles.monthLabel}>
                            <img src={logoSmall} alt="calendar" className={styles.calendarImage} ></img>
                            {format(month, "MMMM",{locale:nb})}
                            {/* ,&nbsp;
                           <p>{year}</p>  */}
                        </div>
                    </div>
                    <div className={styles.datesDiv}>{days}</div>
                </div>


            )
            days = [];

        }
        return <div id="scroll" className={styles.secondDiv1}>
            {months}
        </div>

    }
    if (props.selectedDoc) {
        return (
            <div className={styles.containDiv}>
                <div className={styles.calendarDiv1}>
                    <div className={styles.buttonWrapper1}>
                        {/* <i className='fas fa-angle-left' onClick={scrollPrevWeek} style={{ fontSize: '24px', marginTop: "30px" }}></i> */}
                        <img src={leftArrow} alt="arrow" onClick={scrollPrevWeek}></img>
                    </div>

                    {/* {availableDates ? renderDays() : <p>No dates availabel</p>} */}
                    {renderDays()}


                    <div className={styles.buttonWrapper2}>
                        {/* <i className='fas fa-angle-right' onClick={scrollNextWeek} style={{ fontSize: '24px', marginTop: "30px" }}></i> */}
                        <img src={rightArrow} alt="arrow" onClick={scrollNextWeek}></img>
                    </div>
                </div>

            </div>
        );
    } else { return <p>{parse(t("Loading..."))}</p> }
}

export default withTranslation()(AppointmentCalendar);