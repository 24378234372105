import React from 'react';
import styles from '../Footer/Footer.module.css';
import parse from "html-react-parser";
import { withTranslation } from "react-i18next";

const Footer = ({ ...props }) => {
    const { t } = props;
    return (
        <div className={styles.footerDiv}>
            <div className={styles.condDiv}>
                <a target="_blank" href="https://aurorahelse.s3.eu-north-1.amazonaws.com/public/brukervillar.pdf" >{parse(t("Terms of Use"))}</a>
                <div className={styles.divider}>
                    <a href="https://aurorahelse.s3.eu-north-1.amazonaws.com/public/personvern.pdf" target="_blank" >{parse(t("Privacy Policy"))}</a>
                </div>
            </div>
            <a href="mailto:support@aurorahelse.com" >support@aurorahelse.com</a>
        </div>
    )
}

export default withTranslation()(Footer);
