import React from "react";
import styles from "./LeftPanel.module.css";
import { withTranslation } from "react-i18next";
import logo from '../../../assets/images/logo.svg';
import illustrations from '../../../assets/images/login_illustrate.svg';

const LeftPanel = ({ t }) => {
    return (
    <div className={styles.leftContainer}>
        <img className={styles.logoImg} alt="logo" src={logo}></img>
        <img className={styles.illustration} src={illustrations} alt="illustration"></img>
        <p className={styles.leftHeader}>{t("left_header")}</p>
        <div className={styles.footerLinks}>
            <a target="_blank" href="https://aurorahelse.s3.eu-north-1.amazonaws.com/public/brukervillar.pdf" style={{color:'#000'}} >{t("Terms of Use")}</a>
            <div className={styles.divider}>
                <a href="https://aurorahelse.s3.eu-north-1.amazonaws.com/public/personvern.pdf" target="_blank" style={{color:'#000'}} >{t("Privacy Policy")}</a>
            </div>
        </div>
    </div>
  );
};
export default withTranslation()(LeftPanel);
