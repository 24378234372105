import React, { useState, useEffect } from "react";
import styles from "./NewLogin.module.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import auth from "../Auth/auth";
import LeftPanel from "./LeftPanel/LeftPanel";
import OtpInput from "react-otp-input";
import { verifyUserOtp, verifyPassword } from "../../api";
import alertCircle from '../../assets/images/alert-circle1.svg';
import PhoneInput from "react-phone-input-2";
import eyeOff from '../../assets/images/eye-off.svg';
import eyeOn from '../../assets/images/eye-on.svg';
import logo from '../../assets/images/logo.svg';
import loader from '../../assets/images/loader.gif';

const NewLogin = (props) => {
    const [step, setStep] = useState(1);
    const [phonenumber, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpHash, setOtpHash] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [apiCallComplete, setApiCallComplete] = useState(true);
    const { t } = props;
  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        const isLoggedIn = await auth.isAuthenticated();
        if (isLoggedIn) {
          props.history.push("/dashboard");
        } else {
          props.history.push("/login");
        }
      } catch (err) {
        console.log(err);
      }
    };
    isUserLoggedIn();
  }, []);

  const verifyPass = async (e) => {
    e.preventDefault();
    setShowError(false);
    setApiCallComplete(false);
    try {
      const res = await verifyPassword(phonenumber, password);
      if (res.otpHash) {
          setOtpHash(res.otpHash);
          setStep(2);
      }
    } catch (error) {
      setError(error.response.data.error);
      setShowError(true);
    } finally {
      setApiCallComplete(true);
    }
};

  const verifyOtp = async (e) => {
    e.preventDefault();
    setShowError(false);
    setApiCallComplete(false);
    try {
      const res = await verifyUserOtp(phonenumber, otpHash, otp);
      auth.saveSessionDataInLocalStorage(res);
      props.history.push("/dashboard");
    } catch (error) {
      setError(error.response.data.error);
      setShowError(true);
    } finally {
      setApiCallComplete(true);
    }
};

function handleClick() {
    props.history.push("/reset-password");
}

  return (
    <div className={styles.mainContainer}>
      <LeftPanel></LeftPanel>
      <div className={styles.rightContainer}>
        <div className={styles.outerContainer}>
        <img className={styles.logoImg} alt="logo" src={logo}></img>
          <div className={styles.title}>{t("login_title")}</div>
           <div className={styles.stepper}>
            <div className={step >= 1 ? styles.stepperActive : styles.stepperInactive}></div>
            <div className={step >= 2 ? styles.stepperActive : styles.stepperInactive}></div>
          </div>

          { (step === 1) &&
            <div className={styles.innerContainer}>
              <form onSubmit={verifyPass}>
                {showError &&
                  <div className={styles.errorDiv}>
                      <img alt="" src={alertCircle} className={styles.errorImg}></img><label className={styles.error}>{t(error)}</label>
                  </div>
                  }
                <label className={styles.label}>{t("phone")}</label>
                <div className={styles.phoneInputDiv}>
                  <PhoneInput placeholder="+47 000 00 000"
                    value={phonenumber}
                    onChange={e => setPhone(e)}
                    country={'no'}
                  ></PhoneInput>
                </div>
                <label className={styles.label}>{t("password")}</label>
                <div className={styles.inputDiv}>
                <input  className={styles.input} type= {isPassVisible?"text":"password"} placeholder="xxxxxxxx" value={password} onChange={e => setPassword(e.target.value)} />
                {
                  isPassVisible?
                  <img className={styles.eyeIcon} onClick={()=>setIsPassVisible(!isPassVisible)} src={eyeOn} alt="eye"></img>:
                  <img className={styles.eyeIcon} onClick={()=>setIsPassVisible(!isPassVisible)} src={eyeOff} alt="eye"></img>
                }
                </div>
                <button type="submit" className={password && phonenumber?styles.btn:styles.disabledBtn}>
                {!apiCallComplete && <div className={styles.loader}>
                  <img alt="loader" className={styles.loaderImg} src={loader}></img></div>}
                 <div className={apiCallComplete?styles.buttonText:styles.buttonTextLoading}> {apiCallComplete === true ? t("login_button") : t("loading")} </div></button>
                <span style={{textAlign: 'center'}}>
                  <label className={styles.contactLabel} style={{marginRight: '5px'}}>{t("contact_label")}</label>
                  <a className={styles.contactLink} href="https://www.aurorahelse.com">{t("contact")}</a>
                </span>
                <label className={styles.contactLink} onClick={handleClick}>{t("forgot")}</label>
              </form>
            </div>
          }

          {(step === 2) &&
            <div className={styles.innerContainer}>
              <form onSubmit={verifyOtp}>
                <label className={styles.otpLabel}>{t("otp_label")}</label>
                <label className={styles.phoneNumber}>{phonenumber}</label>
                {showError &&
                  <div className={styles.errorDiv}>
                      <img alt="" src={alertCircle} className={styles.errorImg}></img><label className={styles.error}>{t(error)}</label>
                  </div>
                  }
                <OtpInput
                  numInputs={4}
                    inputStyle={styles.otpInputStyle}
                    containerStyle={styles.otpContainerStyle}
                    separator={<span>&nbsp;</span>}
                    value={otp}
                    onChange={(otpNo) => setOtp(otpNo)}
                    >
              </OtpInput>
                <button type="submit" className={otp?styles.btn: styles.disabledBtn}>
                {!apiCallComplete && <div className={styles.loader}>
                  <img alt="loader" className={styles.loaderImg} src={loader}></img></div>}
                 <div className={apiCallComplete?styles.buttonText:styles.buttonTextLoading}> {apiCallComplete === true ? t("login_button") : t("loading")}</div>
                  
                </button>
               </form>
            </div>
          }
        </div>
        <div className={styles.footerLinks}>
            <a href="https://www.aurorahelse.com/terms-and-conditions" style={{color:'#000'}} >{t("Terms of Use")}</a>
            <div className={styles.divider}>
                <a href="https://www.aurorahelse.com/privacy-policy" style={{color:'#000'}} >{t("Privacy Policy")}</a>
            </div>
        </div>
      </div>
    </div>
  );


};
NewLogin.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(NewLogin);
