import { validateMagicLink, login, validateJWTToken, validateAuroraToken } from "../../api";
// const cryptoJS = require("crypto-js");
import posthog from '../../analytics/posthog';

import cryptoJS from "crypto-js";

const ENCRYPTION_SECRET_KEY = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
const ENCRYPTION_SALT = process.env.REACT_APP_SALT;
class Auth {
  constructor() {
    this.authenticated = false;
  }

  async login(token, email, callback) {
    try {
      const authToken = await validateMagicLink(token, email);
      const sessionData = await login(authToken);
      sessionData.authToken = authToken;
      this.saveSessionDataInLocalStorage(sessionData);
      callback();
    } catch (error) {
      throw error;
    }
  }

  async validateToken(token, callback) {
    try {
      const sessionData = await validateAuroraToken(token);
      this.saveSessionDataInLocalStorage(sessionData);
      callback();
    } catch (error) {
      throw error;
    }
  }

  saveSessionDataInLocalStorage = (sessionData) => {
    const token = sessionData.token;
    const encToken = this.encryptToken(token);
    const user = sessionData.user;
    const authToken = sessionData.authToken;
    const encAuthToken = this.encryptToken(authToken);
    localStorage.setItem("token", token);
    localStorage.setItem("encToken", encToken);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("authToken", authToken);
    localStorage.setItem("encAuthToken", encAuthToken);
  };

  getUserDataInLocalStorage = () => {
    return localStorage.getItem("user");
  }

  encryptToken = (authToken) => {
    var Key = cryptoJS.enc.Utf8.parse(ENCRYPTION_SECRET_KEY);
    var IV = cryptoJS.enc.Utf8.parse(ENCRYPTION_SALT);
    var encryptedText = cryptoJS.AES.encrypt(JSON.stringify(authToken), Key, {
      iv: IV,
      mode: cryptoJS.mode.CBC,
      padding: cryptoJS.pad.Pkcs7,
    });
    return encryptedText.toString(cryptoJS.format.Base64);
  };
  logout(callback) {
    posthog.logoutUser();
    localStorage.clear();
    callback();
  }
  async isAuthenticated() {
    const jwtToken = localStorage.getItem("token");
    if (!jwtToken) {
      return false;
    }
    try {
      await validateJWTToken();
      return true;
    } catch (error) {
      localStorage.clear();
      return false;
    }
  }
}
// singleton object...
const auth = new Auth();
export default auth;
