import React from 'react';
import styles from './AppointmentSummary.module.css';
import line1 from '../../../assets/images/line1.svg';
import line2 from '../../../assets/images/line2.svg';
import path from '../../../assets/images/Path.svg';
import fig1 from '../../../assets/images/fig1.png';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const AppointmentSummary = ({...props}) => {
    const {t} = props;
    const location = useLocation();
    const history = useHistory();

    const clickHandle = ()=>{
        history.push('/dashboard');
    }

    const convertIntoLocalTime = (timeSlot) => {
        var dateParts = location.state.selectedDate.date.toLocaleString().split("-");
        var t_split = timeSlot.split(":")
        //year, month, day, hours, minutes, seconds, millisec
        var date = new Date(Date.UTC(dateParts[2], dateParts[1]-1, dateParts[0], t_split[0], t_split[1], 0))
        var options = { hour12: false, hour: '2-digit', minute: '2-digit' };
        var time = date.toLocaleTimeString([], options)
        return time;
    }
    let firstChar = (location.state.doctor.name).charAt(0);

    return (
        <div className={styles.mainContainer}>
            <img src={fig1} alt="path" className={styles.fig1}></img>
            <img src={line1} alt="fig" className={styles.line1}></img>

            <div className={styles.subContainer}>

                <p className={styles.p1}>{parse(t("Your Appointment has been Booked!"))}</p>
                <div className={styles.figures}>
                    <p>{location.state.service.service}</p>
                </div>
                <img src={line2} alt="fig" className={styles.line2}></img>
                <img src={path} alt="fig" className={styles.fig2}></img>
                <div className={styles.docDiv}>
                    <img src={location.state.doctor.image==null ? `https://aurorahelse.s3.eu-north-1.amazonaws.com/public/Alphabetic_Placeholders/${firstChar}.png` : location.state.doctor.image} alt="doctor" className={styles.docImg}></img>
                    <div className={styles.detail}>
                        <div className={styles.docDiv1}>
                            <p className={styles.docP1}>{parse(t("Appointment with"))}</p>
                            <p >{location.state.doctor.name}</p>
                        </div>

                        <p className={styles.docP3}>{parse(t("Booked on"))} {location.state.selectedDate.date}</p>
                    </div>
                </div>
                <div className={styles.dateContainer}>
                    <div className={styles.date}>
                        <p>{location.state.selectedDate.date}</p>
                    </div>
                    <div className={styles.date}>
                        <p>{parse(t("Time slot"))} : {convertIntoLocalTime( location.state.selectedTime.time.timestamp)}</p>
                    </div>
                </div>
                <div className={styles.note}>
                    <p>{parse(t("Your messaage to the Doctor"))}</p>
                    <div className={styles.para}>
                        <p>"{location.state.notes.note}"</p>
                    </div>
                </div>
            </div>
            <div className={styles.subText}>
                <p>{parse(t("We will send a video conferenc e link to the verified number. Open the link to begin your consultation!"))}</p>
                <button onClick={clickHandle} className={styles.cardBtn}>{parse(t("Done!"))}</button>
            </div>


        </div>
    )
}

export default withTranslation()(AppointmentSummary);
