import React from 'react'
import { useEffect, useState } from 'react';
import { getAvailableDoctors } from '../../../api/index';
import styles from './Doctors.module.css';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const Doctors = ({ ...props }) => {
    const { t } = props;
    const [doctors, setDoctors] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState()
    const [doctorInfo, setDoctorinfo] = useState(props.doctorInfo)
    const selectedStyle = { "backgroundColor": "#A0EBDA80", "border": "none"/* , "boxShadow": " 0px 7px 20px rgba(255, 174, 145,0.5)" */, "fontWeight": "bold", "textAlign": "center" };
    const deselectedStyle = { "backgroundColor": "white", "border": "0.5px solid #000000" };

    useEffect(() => {
        const fetchDoctors = async () => {
            const response = await getAvailableDoctors(props.service_id.id);
            setDoctorinfo(props.doctorInfo);
            if (props.doctorInfo)
                findSequence(props.doctorInfo);
            setDoctors(response);
        }
        fetchDoctors();

    }, [props.doctorInfo])

    const findSequence = (docInfo) => {
        if (docInfo)
            for (var i = 0; i < docInfo.length; i++) {
                if (docInfo[i].resources.length === 1) {
                    if (docInfo[i].resources[0].slots.length > 0) {
                        var selectedDoctorId = docInfo[i].resources[0]
                        setSelectedDoctor(selectedDoctorId);
                        props.selectedDoc(selectedDoctorId);
                        break;
                    }
                    else {
                        continue;
                    }
                }
                else if (!docInfo[i].resources.length < 1) {
                    const checkSlots = docInfo[i].resources.filter((s) => s.slots.length > 0 );
                    if (checkSlots.length>0) {
                        docInfo[i].resources.sort((m, n) =>
                            m.slots.length > 0 && n.slots.length > 0 ?
                                parseInt(m.slots[0].timestamp) - parseInt(n.slots[0].timestamp) : m.slots.length > 0 ? -1 : 1
                        )

                        var selectedDoctorId = docInfo[i].resources[0]
                        setSelectedDoctor(selectedDoctorId);
                        props.selectedDoc(selectedDoctorId);
                        break;
                    }
                    else {
                        continue;
                    }

                }
                else {
                    continue;
                }
            }
    }

    const selectDoctor = (doctor) => {
        setSelectedDoctor(doctor)
        //send back selected doctor
        props.selectedDoc(doctor);
    }

    const getStyles = (doc) => {
        if (selectedDoctor.id === doc.id) {
            return selectedStyle
        }
        else
            return deselectedStyle;
    }

    function renderDoctors() {
        let docs = [];
        doctors.map((doc,index) => {
            let firstChar = (doc.name).charAt(0);
            docs.push(
                <div key={index} className={styles.subDoctorDiv} >
                    <div className={styles.mainDiv1} style={getStyles(doc)} onClick={() => selectDoctor(doc)}>
                        <img className={styles.image} src={doc.image == null ? `https://aurorahelse.s3.eu-north-1.amazonaws.com/public/Alphabetic_Placeholders/${firstChar}.png` : doc.image} alt="No"></img>
                        <div className={styles.docName}>
                            <p className={styles.para}>{doc.name}</p>
                            <p className={styles.head1}>{doc.description}</p>
                        </div>
                    </div>

                </div>
            )
        })

        return docs;
    }


    /*  const scrollPrevWeek = () => {
         const e = document.getElementById("scroll-doc");
         const width = e.getBoundingClientRect().width;
         e.scrollLeft += width - 10;
     }
 
     const scrollNextWeek = () => {
         const e = document.getElementById("scroll-doc");
         const width = e.getBoundingClientRect().width;
         e.scrollLeft -= width - 10;
     } */

    if (doctors && selectedDoctor && props.service_id) {
        return (

            <div className={styles.doctorDiv}>
                {/* <div>
                <i className='fas fa-angle-left' onClick={scrollPrevWeek} style={{ fontSize: '24px' }}></i>
            </div> */}
                <div id="scroll-doc" className={styles.secondDoctorDiv}>

                    {
                        doctors ? renderDoctors() : <p>{parse(t("Loading..."))}</p>
                    }
                </div>
                {/* <div>
                <i className='fas fa-angle-right' onClick={scrollNextWeek} style={{ fontSize: '24px' }}></i>
            </div> */}

            </div>

        );
    }
    else
        return <p>{parse(t("Loading..."))}</p>
};
export default withTranslation()(Doctors);

