import React, { useState, useEffect } from "react";
import styles from "./Invite.module.css";
import { useRouteMatch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import auth from "../Auth/auth";
import singletonCleverTapInstance from '../../analytics/clevertap';

const Invite = (props) => {
  const match = useRouteMatch();
  const { t } = props;
  const [inValidLink, setInValidLink] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const token = match.params.token;
      if(token){
        localStorage.setItem("token",token);
      }
      try {
        await auth.validateToken(token, () => {
          singletonCleverTapInstance.acceptedInvite();
          props.history.push("/welcome");
        });
      } catch (err) {
        setInValidLink(true);
      }
    };
    getUser();
  }, []);

  function handleClick() {
    props.history.push("/login");
  }

  return (
    <div className={styles.container}>
      {inValidLink ? (
        <div>
          <div>
            <strong className={styles.loadingMessage}>
              {t("Seems like an Invalid or Used link!")}
            </strong>
          </div>
          <div className={styles.loginButton} onClick={handleClick}>
            {t("Go to Login")}
          </div>
        </div>
      ) : (
        <div>
          {/* <div className={`text-center`}>
            <Ripple color="#0701f7"></Ripple>
          </div> */}
          <div className={styles.loadingMessage}>
            <strong>{t("Validating...")}</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Invite);
