import React from 'react';
import styles from '../Verification/Verification.module.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { sendOTP, updateSSN, verifyOTP } from '../../../api/index';
import OtpInput from 'react-otp-input';
import { validateNorwegianIdNumber } from 'norwegian-national-id-validator';
import verifyTick from '../../../assets/images/verifyTick.svg';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const Verification = ({ ...props }) => {
    const { t } = props;

    const disabledStyle = { "backgroundColor": "rgba(20,124,101, 0.1)", "pointerEvents": "none", "color": "#147C65", "borderColor": "transparent" };

    const [numberVerified, setNumberVerified] = useState("false");
    const [otpDetails, setOtpDetails] = useState();
    const [otp, setOtp] = useState();
    const [displayOTPSection, setDisplayOTPSection] = useState("none");
    const [waitDisplay, setWaitDisplay] = useState("none");
    const [waitDisplay2, setWaitDisplay2] = useState("none");
    const [otpTime, setOtpTime] = useState(new Date().getTime());

    const [value, setValue] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();

    const [phValue, setPhValue] = useState();
    const [temp, setTemp] = useState();

    useEffect(() => {
        const checkNumber = async () => {
            const response = JSON.parse(localStorage.getItem('user'))
            //Check if Phone Number Exists
            if (response.phoneNumber) {
                setPhValue(response.phoneNumber);
                setPhoneNumber(response.phoneNumber);
                //Check if Phone Number Verified
                if (response.phoneNumberVerified === true) {
                    //setTickDisplay("flex");
                    setNumberVerified("true");
                    setTemp(response.phoneNumber);
                }
                else {
                    //setVerifyDisplay("block");
                    setNumberVerified("false");


                }
            }
        }
        checkNumber();
    }, [])

    //When Phone Number Updated
    const numberChange = (ph) => {
        setDisplayOTPSection("none")
        setOtp("");
        if (temp === "+" + ph) {
            setNumberVerified("true")
        }
        else {
            setNumberVerified("false")
        }

        setPhValue("+" + ph)
    }

    //Send Verification Details
    useEffect(() => {
        props.finalVerify(phoneNumber, numberVerified);
    })


    const sendOtp = async (val) => {
        setNumberVerified("false")
        if (otpTime < new Date().getTime()) {
            setWaitDisplay("none")
            setWaitDisplay2("none")
            setDisplayOTPSection("flex")
            const response = await sendOTP(phValue);
            //Set 1 minute Timer
            setOtpTime(new Date().getTime() + 60000);
            setOtpDetails(response);
        }
        else {
            if (val === "resend") {
                setWaitDisplay("block")
            }
            else
                setWaitDisplay2("block")
        }
    }

    const verifyOtp = async (otpNumber) => {
        if (otpDetails) {
            if (otpNumber.length > 5) {
                const response = await verifyOTP(otpDetails.otpHash, otpNumber);
                if (response) {
                    setPhoneNumber(phValue);
                    setNumberVerified("true");
                    setTemp(phValue)
                    let loggedInUser = JSON.parse(localStorage.getItem('user'));
                    loggedInUser.phoneNumber = phValue;
                    loggedInUser.phoneNumberVerified = true;
                    localStorage.setItem('user', JSON.stringify(loggedInUser));
                }
            }
            setOtp(otpNumber)
        }
    }

    return (
        <div className={styles.mainContainer}>
            <p className={styles.p1}>{parse(t("Please Verify Your Personal Details"))}</p>
            <form>
                {/* <p style={{ margin: '0px', display: phoneError, textAlign: "left", fontSize: '10px', fontStyle: 'italic', fontFamily: 'Helvetica Neue' }}>*Invalid Phone Number</p> */}
                <p className={styles.wait} style={{ display: waitDisplay2 }}>{parse(t("Please wait for a minute"))}</p>
                <div className={styles.phoneSection}>

                    <div className={styles.verify}>

                        <PhoneInput id="p" placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={phone => numberChange(phone)}
                            country={'no'}
                            disabled={true}
                        ></PhoneInput>
                        <div className={styles.verified}>
                            <div className={styles.verifiedTag} style={{ display: numberVerified === "true" ? "flex" : "none" }} >
                                <img src={verifyTick} alt="tick" style={{ marginRight: '5px' }}></img>
                                <p>{parse(t("Verified"))}</p>
                            </div>

                            {<div onClick={() => sendOtp()} className={styles.verifyBox} style={{ display: numberVerified === "true" ? "none" : "flex", cursor: 'pointer' }}>
                                <p  >{parse(t("Verify"))}</p>
                            </div>
                            }
                            {/* <p onClick={() => handleChangeClick()} style={{ display: noUpdate, margin: '0px', textAlign: "left", fontSize: '14px', fontFamily: 'Butler', fontWeight: '700', color: '#0701F7', cursor: 'pointer', pointerEvents: tickDisplay === "block" ? "none" : "auto" }}>Change</p> */}
                        </div>


                        {/* <p onClick={() => verifyNumber()} style={{ display: tickDisplay, margin: '0px', textAlign: "left", fontSize: '14px', fontStyle: 'italic', fontFamily: 'Helvetica Neue' }}>Verify</p> */}
                    </div>
                    <div className={styles.otpSection} style={{ display: displayOTPSection }}>
                        <p className={styles.info}>{parse(t("Enter the OTP sent to"))} {phValue}</p>
                        <OtpInput
                            numInputs={6}
                            inputStyle={styles.otpInputStyle}
                            separator={<span>&nbsp;</span>}
                            onChange={(otpNo) => verifyOtp(otpNo)}
                            isDisabled={numberVerified === "true" ? true : false}
                            disabledStyle={disabledStyle}
                            value={otp}
                        >
                        </OtpInput>
                        <div className={styles.resend}>
                            <div className={styles.resendSub} >
                                <p className={styles.info}> {parse(t("Didn't Recieve OTP ?"))}</p>
                                <p className={styles.resendOtp} onClick={() => sendOtp("resend")}>{parse(t("Resend OTP"))}</p>
                            </div>

                            <p className={styles.wait} style={{ display: waitDisplay }}>{parse(t("Please wait for a minute"))}</p>
                        </div>


                    </div>

                </div>
                <p className={styles.info} style={{ marginBottom: '30px' }}>{parse(t("Need to verify number through OTP"))}</p>
                
                {/* <p className={styles.info}>{parse(t("Your information is safe with us"))}&nbsp;<a href="https://www.aurorahealth.io/privacy-policy">{parse(t("Privacy Policy"))}</a><span style={{ marginLeft: '130px' }}>*{parse(t("Optional"))}</span></p> */}
                <p className={styles.info}>{parse(t("Your information is safe with us"))}&nbsp;<a href="https://www.aurorahealth.io/privacy-policy">{parse(t("Privacy Policy"))}</a></p>
            </form>
        </div>
    )
}

export default withTranslation()(Verification);
