import React, { useState } from 'react';
import Header from './Header/Header';
import Cards from './Cards/Cards';
import Footer from './Footer/Footer';
import singletonCleverTapInstance from '../../analytics/clevertap';
import SnapmedModal from './SnapmedModal/SnapmedModal';
import Tutorial from './Tutorial/Tutorial';
import { useEffect } from 'react';


export default function Dashboard() {
    singletonCleverTapInstance.visitDashboard();
    const [modalDisplay, setmodalDisplay] = useState("none");
    const [tutorialDisplay, setTutorialDisplay] = useState("blur(0px)");
    const [subscription, setSubscription] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));

    

    const getProp = (value) => {
        if (value === "Hudlege") {
            setmodalDisplay("flex");
        }
    }

    const getTutorial = () => {
        if (user.lastLogin === null) {
            if(user.tutorialVisited === "False")
                return <Tutorial getEffect={showUserTutorial}></Tutorial>
        } 
    }

    const showUserTutorial = (value) => {
        setTutorialDisplay(value);
    }

    const getSubscription = (value) => {
        setSubscription(value);
    }

    return (
        <React.Fragment>
            {getTutorial()}
            <SnapmedModal setDisplay={modalDisplay}></SnapmedModal>
            <div id="dash" style={{ filter: tutorialDisplay }}>
                <Header subscription={subscription}></Header>
                <Cards getService={getProp} getSubscription={getSubscription} ></Cards>
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}
