import React from 'react';
import styles from '../Tutorial/Tutorial.module.css';
import doc from '../../../assets/images/tut_doc.png';
import doc1 from '../../../assets/images/doc1.svg';
import doc2 from '../../../assets/images/doc2.svg';
import doc3 from '../../../assets/images/doc3.svg';
import tutorialLogout from '../../../assets/images/logout_text.svg';
import { useState } from 'react';
import { withTranslation } from "react-i18next";
import arrow from '../../../assets/images/arrow.svg';
import parse from "html-react-parser";
import clock from '../../../assets/images/clock.svg';
import cam from '../../../assets/images/cam.svg';
import cardText1 from '../../../assets/images/card_text1.svg';
import cardText2 from '../../../assets/images/card_text2.svg';
import { useEffect } from 'react';

const Tutorial = ({ ...props }) => {
    const { t } = props;

    const [showTutorial,setShowTutorial]=useState("flex");

    useEffect(() => {
        const setTutorial = async () => {
            props.getEffect('blur(3px)');
        }
        setTutorial();
    }, [])

    const displayTutorial =()=>{
        const user = JSON.parse(localStorage.getItem('user'));
        user.tutorialVisited = "True";
        localStorage.setItem('user', JSON.stringify(user));
        setShowTutorial("none");
        props.getEffect('blur(0px)');
    }

    
    const additionalDoctors = () => {
        let docImg =[doc3,doc1,doc2];
        let docList = [];
        let i = 1;
        for (i = 1; i < 4; i++) {
            let j = -10 * i;
            docList.push(
                <img src={docImg[i-1]} alt="doctor" className={styles.extraDoc1} style={{ zIndex: `${i}`, position: "relative", left: `${j}%` }}></img>
            )
        }


        return <div className={styles.addDocExtra}>
            <div className={styles.addDoc}>
                {docList}
            </div>
            {/* { <p>+{doctor.length-1} {doctor.length==2?parse(t("Doctor")):parse(t("Doctors"))}</p>} */}
            {<p>+3 Leger</p>}
        </div>


    }


    return <div className={styles.mainDiv} style={{display:showTutorial}} onClick={()=>displayTutorial()}>
        <div className={styles.mainDiv2}>
            <div className={styles.nav}>
                <img className={styles.tutorialLogout} src={tutorialLogout}></img>
            </div>
            <div id="header" className={styles.headerDiv}>
                <div className={styles.imageDiv}></div>
                <div className={styles.containDiv}  >
                    <div className={styles.notificationDiv}>
                        <img className={styles.tutorialNotification}></img>
                        <div id="appointment1" className={styles.docDiv}  >
                            <div className={styles.subDocDiv} >
                                <img src={doc} alt="doctor" className={styles.docExtraImage1}></img>
                                <div className={styles.appDiv}>
                                    <p className={styles.appDetail} >Fysioterapeut : Dr Søren Klausen  </p>
                                    <div className={styles.time}>
                                        <img src={clock} alt="clock" className={styles.callImg}></img>
                                        <p className={styles.appDetail} >I dag, 8:30 - 9:30</p>
                                    </div>
                                </div>
                                <div className={styles.cardBtn}>
                                    <p>Delta</p>
                                    <img src={arrow} alt="doctor" className={styles.btnArrow}></img>
                                </div>
                            </div>
                            <div className={styles.subDocDivSecond} >
                                <img src={doc} alt="doctor" className={styles.docExtraImage1}></img>
                                <div className={styles.appDiv}>
                                    <p className={styles.appDetail} >Fysioterapeut: Dr Søren Klausen</p>
                                    <div className={styles.time}>
                                        <img src={clock} alt="clock" className={styles.callImg}></img>
                                        <p className={styles.appDetail} >I dag, 8:30 - 9:30</p>
                                    </div>
                                </div>
                                <div className={styles.callIcon}>
                                    <img src={cam} alt="doctor" className={styles.docExtraImage}></img>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.cardDiv}>

            <div className={styles.subCardDiv1} >
                <div className={styles.cardText1}>
                    <img src={cardText1} ></img>
                </div>
                <div className={styles.cards} id="cards1" style={{ width: '100%' }}>
                    <div className={styles.cardSub} >
                        <div className={styles.cardSubDiv1}     >
                            <img src="https://aurorahelse.s3.eu-north-1.amazonaws.com/public/2.svg" alt="illustration" className={styles.illustration}></img>
                            <div className={styles.cardBtn}>
                                <p>{parse(t("Book"))}</p>
                                <img src={arrow} alt="arrow" className={styles.btnArrow}></img>
                            </div>

                        </div>
                        <div className={styles.describe}>
                            <p className={styles.p1} style={{fontSize:'20px'}} >Fysioterapeut</p>
                            <p className={styles.p2}>Online fysioterapi for god helse gjennom arbeid med kropp og bevegelse</p>
                        </div>
                    </div>
                    <div className={styles.docDiv1}>
                        <img src={doc} alt="doctor" className={styles.docImage}></img>
                        <div className={styles.detailDoc}>
                            <p className={styles.p1}>Dr Søren Klausen</p>
                            <p className={styles.p2}>Fysioterapeut</p>
                        </div>

                        {additionalDoctors()}

                    </div>
                </div>
                <div className={styles.cardText2}>
                    <img src={cardText2} ></img>
                </div>
            </div>


        </div>





    </div>;

}

export default withTranslation()(Tutorial);