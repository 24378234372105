import React from "react";
import styles from "./LoginButton.module.css";
import { withTranslation } from "react-i18next";
import rightArrow from '../../../assets/images/rightArrow.svg';
import crossCircle from '../../../assets/images/x-circle.svg';
import alertCircle from '../../../assets/images/alert-circle.svg';
import { useState } from "react";
import tick from '../../../assets/images/whiteTick.svg';

const LoginButton = ({ functions: { login, validate }, email, emailResponse, t }) => {

  const [showInput, setShowInput] = useState(true);
  const displayComponent = () => {
    if (emailResponse === 'ACCEPTED') {
      //Normal scenario, send magic link, no text box afterwards
      return (
        <div>
          <div className={styles.emailSent} >
            {t("We have sent a verification link to")} {email}
            <br />
          </div>
          <div className={styles.warning}>
            <img src={crossCircle}></img>
            <p>{t("The link will expire in 60 mins")}</p>
          </div>
          {/* <div className={styles.warning}>
            <img src={alertCircle}></img>
            <p>{t("Please don't share it with others")}</p>
          </div> */}
        </div>
      )
    }
    else if (emailResponse === 'SENT') {
      //Sent but not accepted
      return (
        <div >
          <div className={styles.warning}>
            <img src={alertCircle}></img>
            <p>{t("You have received an invite on this email address, Kindly accept it to activate this account")}</p>
          </div>
        </div>
      )
    }
    else if (emailResponse === 'User not authorized') {
      //User not authorized
      return (
        <div >
          <div className={styles.warning}>
            <img src={alertCircle}></img>
            <p>{email} {t("is not authorized to use aurora. Kindly confirm with your organisation")}</p>
          </div>
        </div>
      )
    }
    else if (emailResponse === 'Something went wrong') {
      //Something went wrong
      return (
        <div>
          <div className={styles.warning}>
            <img src={alertCircle}></img>
            <p>{t("Something went wrong")}</p>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={styles.warningDiv}>
      <div className={styles.emailButton} style={{ display: emailResponse === 'ACCEPTED' ? 'none' : 'flex' }}>
        <div className={styles.emailSent} style={{ display: !emailResponse ? 'block' : 'none' }}>
          <p>{t("We shall email you a magic link for a password-free sign in")}</p>
        </div>

        <div className={styles.emailDiv} >
          <input onKeyDown={async (e) => await validate(e)} id="email" type="email" placeholder={t("Your Email ID")}></input>
          <div onClick={async () => await login()} style={{ backgroundColor: !emailResponse ? 'transparent' : 'black' }} className={styles.btnDiv}><img className={styles.arw} alt="arrow" src={!emailResponse ? rightArrow : tick}></img></div>
        </div>

      </div >
      {displayComponent()}
    </div>

  )
};

export default withTranslation()(LoginButton);
