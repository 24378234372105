import React from "react";
import "./index.css";
import { Login, Validate, Invite, ProtectedRoute, NewLogin, OnboardUser, ResetPassword} from "./components";
import styles from "./App.module.css";
import { ToastProvider } from "react-toast-notifications";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { withTranslation } from "react-i18next";
import NewDashboard from './components/NewDashboard/Dashboard';
import BookingSteps from "./components/Appointment/BookingSteps";
import AppointmentSummary from "./components/Appointment/AppointmentSummary/AppointmentSummary";
import Tutorial from "./components/NewDashboard/Tutorial/Tutorial";
import Welcome from "./components/Welcome/Welcome";
import DownloadDetails from "./components/DownloadDetails";
class App extends React.Component {
  // Add a request interceptor

  setAxiosInterceptors = () => {
    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = token;
      return config;
    });
  };

  render() {
    const { t } = this.props;
    this.setAxiosInterceptors();
    return (
      <I18nextProvider i18n={i18n}>
        <ToastProvider placement="bottom-right">
          <Router>
            <div className={styles.container}>
              <Switch>
                <Route path="/login" exact component={NewLogin} />
                <Route path="/reset-password" exact component={ResetPassword} />
                <Route path="/onboard-user/:email/:phone" exact component={OnboardUser} />
                <ProtectedRoute path="/welcome" exact component={Welcome}/>
                {/* <Route path="/welcome" component={Welcome} /> */}
                <ProtectedRoute path="/dashboard" exact component={NewDashboard} />
                <Route path="/validate/:token" component={Validate} />
                <ProtectedRoute path="/booking" component={BookingSteps} />
                <Route path="/summary" component={AppointmentSummary} />
                <Route path="/invite/:token" component={Invite} />
                <Route path="/download" component={DownloadDetails} />
                {/* <Route path='/NewDashboard' component={NewDashboard}></Route> */}
                <Route
                  path="/"
                  exact
                  render={() => <Redirect to="/dashboard" />}
                />
                <Route path="*" render={() => <div>{t("No Match")}</div>} />
              </Switch>
            </div>
          </Router>
        </ToastProvider>
      </I18nextProvider>
    );
  }
}

export default withTranslation()(App);
