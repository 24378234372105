import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './TimeSlots.module.css';
import parse from "html-react-parser";
import { withTranslation } from "react-i18next";

const TimeSlots = ({ ...props }) => { 
    const { t } = props;

    const [timeSlots, setTimeSlots] = useState();
    const [selectedSlot, setSelectedSlot] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const selectedStyle = { "backgroundColor": "#A0EBDA80", "border": "none", "borderRadius": "10px"/* , "boxShadow": " 0px 9px 30px rgb(255, 174, 145)" */, "fontStyle": "italic", "fontWeight": "800", "textAlign": "center" };

    let timeArr = [];

    useEffect(() => {
        setTimeSlots(props.times)
        setSelectedDate(props.dateSelected);
    }, [props.times])



    const getStyles = (slot) => {
        if (slot === selectedSlot) {
            return selectedStyle;
        }
        return null;
    };

    const slotSelection = (slot) => {
        setSelectedSlot(slot);
        props.selectedTime(slot);
    }

    const convertIntoLocalTime = (timeSlot) => {
        var t = new Date(selectedDate);
        var t_split = timeSlot.timestamp.split(":")
        //year, month, day, hours, minutes, seconds, millisec
        var date = new Date(Date.UTC(t.getFullYear(), (t.getMonth()), t.getDate(), t_split[0], t_split[1], 0))
        var options = { hour12: false, hour: '2-digit', minute: '2-digit' };
        var time = date.toLocaleTimeString([], options)
        return time;
    }

    function setTiming() {
        if (timeSlots)
            for (let i = 0; i < timeSlots.length; i++) {
                var time = convertIntoLocalTime(timeSlots[i]);
                timeArr.push(<div key={i} className={styles.time} style={getStyles(timeSlots[i])} onClick={() => slotSelection(timeSlots[i])}>{time}</div>);
            }
        return <div className={styles.container}>
            <div className={styles.timeRow}>
                <div className={styles.midDiv}>{timeArr.length > 0 ? timeArr : parse(t("No slots svailable"))}</div>
            </div>
        </div>
    }

    if (props.times && timeSlots) {
        
        return setTiming();
    }
    else {
        return <p>{parse(t("No slots svailable"))}</p>
    }

};

export default withTranslation()(TimeSlots);