import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../Auth/auth";
// import Ripple from "@bit/joshk.react-spinners-css.ripple";
import styles from "./ProtectedRoute.module.css";
import { withTranslation } from "react-i18next";
import loadingAnimation from "../../assets/images/logo-animation.svg";
import queryString from 'query-string';
import singletonPosthogInstance from '../../analytics/posthog';
import singletonCleverTapInstance from '../../analytics/clevertap';


class ProtectedRoute extends React.Component {
  state = {
    loading: true,
    isAuthenticated: false,
  };
  async componentDidMount() {

    //Capture posthog event
    let queryParams = queryString.parse(this.props.location.search);
    singletonPosthogInstance.capture(queryParams.user_email,queryParams.email_type);

    const authenticated = await auth.isAuthenticated();
    if (authenticated) {
      let user = JSON.parse(auth.getUserDataInLocalStorage());
      singletonCleverTapInstance.loginUser(user);
      this.setState({ loading: false, isAuthenticated: true });
    } else {
      this.setState({ loading: false, isAuthenticated: false });
    }
  }

  render() {
    const { t } = this.props;
    const { component: Component, ...rest } = this.props;
    if (this.state.loading) {
      return (
        <div className={styles.container}>
          <div>
            <div className={`text-center ${styles.loadingAnimationBox}`}>
              <object type="image/svg+xml" data={loadingAnimation}>svg-animation</object>
            </div>
            <div className={styles.loadingMessage}>
              <strong>{t("Loading...")}</strong>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) => {
            if (this.state.isAuthenticated) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }}
        />
      );
    }
  }
}

export default withTranslation()(ProtectedRoute);
