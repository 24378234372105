import clevertap from 'clevertap-web-sdk';

class Clevertap {
    clevertapProjectId = process.env.REACT_APP_CLEVERTAP_PROJECT_ID;

    constructor() {
        clevertap.privacy.push({optOut: false});
        clevertap.privacy.push({useIP: false});
        clevertap.init(this.clevertapProjectId);
    }

    loginUser(user) {
        clevertap.onUserLogin.push({
            "Site": {
              "Name": user.firstName + user.lastName,            // String
              "Identity": user.id,              // String or number
              "Email": user.emailId,         // Email address of the user
              "Phone": user.phoneNumber,           // Phone (with the country code)
              "DOB": new Date(),                 // Date of Birth. Date object
              "MSG-email": true,                // Disable email notifications
              "MSG-push": true,                  // Enable push notifications
              "MSG-sms": true,                   // Enable sms notifications
              "MSG-whatsapp": true,              // Enable WhatsApp notifications
            }
           })
    }

    visitDashboard(){
        clevertap.event.push("Visited dashboard");
    }

    acceptedInvite(){
        clevertap.event.push("Invitation accept", {
            "Date": new Date()
        });
    }

    visitWelcome(){
        clevertap.event.push("Visited welcome");
    }

    viewedService(serviceName){
        clevertap.event.push("Viewed a service", {
            "service": serviceName
        });
    }

    selectTimeSlot(serviceName){
        clevertap.event.push("Selected a timeslot", {
            "service": serviceName
        });
    }

    bookedService(serviceName){
        clevertap.event.push("Booked a service", {
            "service": serviceName
        });
    }

    redirectToSnapmed(){
        clevertap.event.push("Redirected to Snapmed");
    }

    captureFeedback(date){
        clevertap.event.push("Given Feedback",{
            "dateTime": date,
        })
    }

}
  
  const singletonCleverTapInstance = new Clevertap();
  
  Object.freeze(singletonCleverTapInstance); //immutable

  export default singletonCleverTapInstance;
