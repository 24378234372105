import axios from "axios";
import posthog from '../analytics/posthog';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL;

export const verifyPassword = async (phoneNumber, password) => {
  try {
    const phone = phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber;
    const body = { phoneNumber: phone, password };
    const response = await axios.post(`${baseUrl}/auth/verify-password`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyUserOtp = async (phoneNumber, otpHash, otp) => {
  try {
    const phone = phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber;
    const body = { phoneNumber: phone, otpHash, otp };
    const response = await axios.post(`${baseUrl}/auth/login-otp`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyPhonenumber = async (phoneNumber) => {
  try {
    const phone = phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber;
    const body = { phoneNumber: phone };
    const response = await axios.post(`${baseUrl}/auth/verify-phone`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (phoneNumber, otpHash, otp) => {
  try {
    const phone = phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber;
    const body = { phoneNumber: phone, otpHash, otp };
    const response = await axios.post(`${baseUrl}/auth/verify-otp`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setUserPassword = async (phoneNumber, otpHash, password) => {
  try {
    const phone = phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber;
    const body = { phoneNumber: phone, otpHash, password };
    const response = await axios.post(`${baseUrl}/auth/set-password`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setPhonenumber = async (phoneNumber, email) => {
  try {
    const phone = phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber;
    const body = { phoneNumber: phone, email };
    const response = await axios.post(`${baseUrl}/auth/set-phone`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async (email) => {
  try {
    await posthog.loginUser(email);
    const body = { email };
    const {
    data: { email_status },
    } = await axios.post(`${baseUrl}/user`, body);
    const modifiedData = { email_status};
    return modifiedData;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const sendMagicLink = async (emailId) => {
  console.log(emailId)
  const currentUrl = window.location.origin;
  const body = {
    emailId: emailId,
    validationUrl: currentUrl + "/validate",
  };
  try {
    await axios.post(`${baseUrl}/auth/magic-link`, body);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validateMagicLink = async (token,email) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/auth/magic-link/${token}`
    );
    await posthog.verifyUser(email);
    const authToken = data;
    return authToken;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validateAuroraToken = async (token) => {
  try {
    const { data } = await axios.get(`${baseUrl}/auth/validate-user-token`)
    // await posthog.verifyUser(email);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const login = async (authToken) => {
  try {
    const body = {
      code: authToken,
      type: "auth-server",
    };
    const { data } = await axios.post(`${baseUrl}/auth/login`, body);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validateJWTToken = async () => {
  try {
    await axios.get(`${baseUrl}/protected/auth/validate-token`);
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const getUpcomingAppointment = async () => {
  const userString = localStorage.getItem("user");
  const user = JSON.parse(userString);

  try {
    const { data } = await axios.get(
      // `${baseUrl}/protected/appointment/upcoming/${user.id}` old code
      `${baseUrl}/protected/service/scheduler/appointments`
    );
    const appointments = data;
    return appointments;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getSchedulerServices = async () => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/protected/service/scheduler/all`
    );
    const services = data;
    return services;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getSchedulerServiceDoctors1 = async (service_id) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/protected/service/scheduler/resource/${service_id}`
    );
    const services = data;
    return services;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//New APIs

export const fetchServices = async () =>{
    try{
        const response = await axios.get(`${baseUrl}/protected/service/scheduler/all` )
        return(response);
    }
    catch(error){
        console.log(error);
    }
}

export const fetchServiceDoctors = async (id) => {
    try{
        const response = await axios.get(`${baseUrl}/protected/service/scheduler/resource/${id}` )
        return(response);
    }
    catch(error){
        console.log(error);
    }
}

export const fetchUpcomingAppointments = async (id) => {
    try{
        const response = await axios.get(`${baseUrl}/protected/service/scheduler/appointments` )
        return(response.data);
    }
    catch(error){
        console.log(error);
    }
}

export const getSchedulerServiceDoctors = async (service_id,data) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/protected/service/scheduler/resource/${service_id}`,data
      );
      const services = data;
      return services;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  
  export const getAvailableDoctors = async (service_id,data) => {
    try {
      const response = await axios.get(
        `${baseUrl}/protected/service/scheduler/resource/${service_id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  
  export const getAvailableDates = async (service_id,startDate,endDate) => {
    try {
      const response = await axios.get(
       ` ${baseUrl}/protected/service/scheduler/doctor-slot/${service_id}?start_date=${startDate}&end_date=${endDate}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  export const phoneNumberVerification = async (authToken) => {
    try {
      const body = {
        code: authToken,
        type: "auth-server",
      };
      const response = await axios.post(
        `${baseUrl}/auth/login`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  export const sendOTP = async (data) => {
    try {
      let body={
        phone:data
      }
      const response = await axios.post(
        `${baseUrl}/protected/auth/send-otp`,body
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  export const verifyOTP = async (hash,userOtp) => {
    try {
     let data={
        otpHash:hash,
        otp:userOtp
      }
      const response = await axios.post(
        `${baseUrl}/protected/auth/verify-otp`,data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  export const updateSSN = async (ssno) => {
    try {
     let data={
        ssn:ssno
      }
      const response = await axios.post(
        `${baseUrl}/protected/user/update-ssn`,data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  export const bookAppointment = async (data) => {
    try {
     
      const response = await axios.post(
        `${baseUrl}/protected/service/scheduler/book-appointment`,data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      return(error.response.data.error);
      //throw error;
    }
  }
  export const updatePhone = async (data) => {
    try {
     
      const response = await axios.post(
        `${baseUrl}/protected/user/update-phonenumber`,data
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }





