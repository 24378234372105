import React from 'react';
import Modal from 'react-modal';
import styles from './BookingSteps.module.css';
import close from '../../assets/images/close.svg';
import parse from "html-react-parser";
import { withTranslation } from "react-i18next";

 const TimeSlotAlreadyBookedModal=({...props}) =>{
  const { t } = props;
  return (
    <Modal isOpen={props.showModal} shouldCloseOnEsc={true} className={styles.modalStyles} overlayClassName={styles.modalOverlay} >
      <div className={styles.modalCloseDiv} onClick={props.closeModal}><img src={close}></img></div>
      <div className={styles.modalContainer}>
        <p className={styles.p1}>{parse(t("Please find a new slot!"))}</p>
        <p className={styles.p2}>{parse(t("This slot is unfortunately not available anymore"))}</p>
        <button className={styles.modalButton} onClick={props.closeModal}>{parse(t("Find a new slot"))}</button>
      </div>
    </Modal>
  )
}
export default withTranslation()(TimeSlotAlreadyBookedModal);
