import posthog from 'posthog-js';

class Posthog {
    constructor() {
        posthog.init('Zfki77AEAf0t1pwAZGshSfyMABBCI6xVCb_1O-FJblg',{api_host:'https://app.posthog.com'});
    }
  
    loginUser(email) {
        posthog.identify(
            email, // distinct_id, required
        );
        posthog.people.set_once({email: email});
        posthog.capture('Tried to login : product',{ $set_once: { email: email } });
    }

    verifyUser(email) {
        posthog.identify(
            email, // distinct_id, required
        );
        posthog.people.set_once({email: email});
        posthog.capture('Magiclink verify : product',{ $set_once: { email: email } });
    }

    capture(email,eventName) {
        posthog.identify(
            email, // distinct_id, required
        );
        posthog.people.set_once({email: email});
        posthog.capture(eventName+' : product');
    }

    logoutUser(){
        posthog.reset();
    }

}
  
  const singletonPosthogInstance = new Posthog();
  
  Object.freeze(singletonPosthogInstance); //immutable

  export default singletonPosthogInstance;