import React from 'react';
import styles from '../SubHeader/SubHeader.module.css';
import icon from '../../../assets/images/icon.svg';
import { fetchServiceDoctors } from '../../../api/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const SubHeader = ({...props}) => {
    const {t} = props;
    const [doctor, setDoctor] = useState();
    const [service,setService] = useState();

    useEffect(() => {
        const getDoctors = async () => {
            setService(props.service_id);
            const response = await fetchServiceDoctors(props.service_id.id);
            setDoctor(response.data)
        }
        getDoctors();
    }, [])

    const additionalDoctors = () => {
        //Fetch doctors of a Service
        let doc=[];
        let i=1;
        let k =doctor.length-1;
        let j;
        for (i = 0; i < doctor.length; i++,k--) {
            let firstChar = (doctor[i].name).charAt(0);
            j = 10*k-10;
            doc.push(
                <img key={i} src={doctor[i].image==null ? `https://aurorahelse.s3.eu-north-1.amazonaws.com/public/Alphabetic_Placeholders/${firstChar}.png` : doctor[i].image} alt="doctor" className={styles.docExtraImage1} style={{ /* zIndex: `${i}`, */ position: "relative", left: `${j}%` }}></img>
            )
        }

        if (doctor.length > 1) {
            return <div className={styles.addDocExtra}>
                <div className={styles.addDoc}>
                    {doc}
                </div>
                {/* <p>+{doctor.length - 0} {parse(t("Doctors"))}</p> */}
            </div>
        }
        else {
            return <div className={styles.addDocExtra}>
                <div className={styles.addDoc}>
                    {doc}
                </div>

            </div>
        }

        //return doc;
    }

    if(service)
    return (
        <React.Fragment>
            <div className={styles.headDiv} >
                <div className={styles.headSubDiv}>
                    <img className={styles.subImg} alt="doctor" src={props.service_id.image}></img>

                </div>
                <div className={styles.serviceDiv} >
                    <div className={styles.serviceHead} >
                        <p className={styles.p1}>{service.title}</p>
                        <p className={styles.p2}>{service.description}</p>
                    </div>
                    
                    <div className={styles.docs}>
                    {doctor?additionalDoctors():<p>{parse(t("Loading..."))}</p>}    
                    </div>
                    
                </div>
            </div>
            <hr ></hr>
        </React.Fragment>

    )
    else{
        return <p>{parse(t("Loading..."))}</p>
    }

}

export default withTranslation()(SubHeader);