import React, { useState, useEffect } from "react";
import styles from "./Validate.module.css";
import { useRouteMatch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import auth from "../Auth/auth";
// import Ripple from "@bit/joshk.react-spinners-css.ripple";
const Validate = (props) => {
  const match = useRouteMatch();
  const { t } = props;
  const [inValidLink, setInValidLink] = useState(false);

  useEffect(() => {
    const login = async () => {
      const token = match.params.token;
      const email = match.params.email;
      try {
        await auth.login(token, email, () => {
          props.history.push("/dashboard");
        });
      } catch (err) {
        setInValidLink(true);
      }
    };
    login();
  }, []);

  function handleClick() {
    props.history.push("/login");
  }

  return (
    <div className={styles.container}>
      {inValidLink ? (
        <div>
          <div>
            <strong className={styles.loadingMessage}>
              {t("Seems like an Invalid or Used link!")}
            </strong>
          </div>
          <div className={styles.loginButton} onClick={handleClick}>
            {t("Go to Login")}
          </div>
        </div>
      ) : (
        <div>
          {/* <div className={`text-center`}>
            <Ripple color="#0701f7"></Ripple>
          </div> */}
          <div className={styles.loadingMessage}>
            <strong>{t("Validating...")}</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Validate);
