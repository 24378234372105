import React from 'react';
import styles from '../Cards/Cards.module.css';
import arrow from '../../../assets/images/arrow.svg';
import { fetchServices } from '../../../api/index';
import { useEffect } from 'react';
import { useState } from 'react';
import DoctorDetail from '../CardDoctors/CardDoctors';
import {  useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import auth from "../../Auth/auth";
import singletonCleverTapInstance from '../../../analytics/clevertap';
import SnapmedModal from '../SnapmedModal/SnapmedModal';
import DrDropinModal from '../../DrDropin/DrDropinModal';

const Cards = ({...props}) => {
    const {t} = props;
    const history = useHistory();
    const snapmedConsultationURL = process.env.REACT_APP_SNAPMED_CONSULTATION_URL;
    const user = JSON.parse(auth.getUserDataInLocalStorage());
    const email = user["emailId"];
    const [services, setServices] = useState([]);
    const [subscription, setSubscription] = useState('pluss');
    const [companyCode, setCompanyCode] = useState('');
    const [showDrDropinModal, setShowDrDropinModal] = useState(false);
    const subscriptionMap = {
        'pluss': ['Personlig Trener', 'Ernæringsfysiolog', 'Fysioterapeut', 'Psykolog', 'Allmennlege',  'Årlig helsesjekk'],
        'basis': ['Psykolog', 'Allmennlege', 'Årlig helsesjekk'],
        'standard': ['Psykolog', 'Allmennlege', 'Fysioterapeut', 'Årlig helsesjekk'],
        'test': ['Personlig Trener', 'Ernæringsfysiolog', 'Fysioterapeut', 'Psykolog', 'Allmennlege',  'Årlig helsesjekk'],
        'basis+': ['Allmennlege',  'Årlig helsesjekk'],
        'basis2': ['Allmennlege', 'Fysioterapeut','Årlig helsesjekk'],
        'basis-light': ['Allmennlege']
    };

    useEffect(() => {
        const getService = async () => {
            const response = await fetchServices();
            const sub = response.data['subscription'];
            setSubscription(sub);
            props.getSubscription(sub);

            let services = response.data['services'];
            let snapmedService = {
                'id': 6,
                'title': 'Hudlege',
                'image': 'https://aurorahelse.s3.eu-north-1.amazonaws.com/public/2.svg',
                'description': 'Konsultasjon med hudlege via bilde og video på dagen. Få medisinsk rådgivning, resepter og videre henvisninger ved behov.',
                'large_description': null
            }
            services.push(snapmedService);
            if (response.data['isAnnualHealthCheck']) {
                let annualService = {
                'id': 7,
                'title': 'Årlig helsesjekk',
                'image': 'https://aurorahelse.s3.eu-north-1.amazonaws.com/public/heart_logo.svg',
                'description': 'Tjenesten inkluderer samtale med legen, relevante undersøkelser og laboratorieprøver.',
                'large_description': null
                }
                services.push(annualService);
                setCompanyCode(response.data['companyCode']);
            }
            const filteredServices = services.filter(service => subscriptionMap[sub].includes(service.title));
            setServices(filteredServices.sort((a,b) => a.id - b.id));
        }       
        getService();  
    }, []);

    const handleAnnualService = () => {
        setShowDrDropinModal(true);
    }

    const handleHudlege = () => {
        singletonCleverTapInstance.viewedService("Hudlege");
        //let id = 0;
        const encToken = localStorage.getItem("encToken");
        if (!snapmedConsultationURL || snapmedConsultationURL === "") {
            return;
        }
        document.querySelector("#dash").style.filter = "blur(5px)";
        document.getElementById('snapModal').style.filter="blur(0px)";
        props.getService("Hudlege");
        
        const timer = setTimeout(() => {
            props.getService("Other");
            singletonCleverTapInstance.redirectToSnapmed();
            window.location.href = `${snapmedConsultationURL}&token=${encToken}&id=1&email=${email}`;    
          }, 3000);
          //return () => clearTimeout(timer);}
        //props.getService("Other");
        
    };

    const handleServices = (docid) => {
        singletonCleverTapInstance.viewedService(docid.title);
        history.push({ pathname: '/booking', state: { service_id: docid, title: docid.title, subscription: subscription } })
    }

    const handleClick = (service) => {
        switch (service['id']) {
            case 6:
                handleHudlege();
                break;
            case 7:
                handleAnnualService();
                break;
            default:
                handleServices(service);
                break;
        }
    }

    function closeModal() {
        setShowDrDropinModal(false);
    }

    if (services) {
        return (
            <div className={styles.wrapperDiv}>
                {services.map((service) => {
                    return (
                        <div className={styles.subCardDiv1} >
                            <div className={styles.cards} key={service.id}>
                                <div className={styles.cardSub}>
                                    <div className={styles.cardSubDiv1}>
                                        <img src={service.image} alt="illustration" className={styles.illustration}></img>
                                        <div className={styles.cardBtn} key={service.id} onClick={() => handleClick(service)}>
                                            <p>{parse(t("Book"))}</p>
                                            <img src={arrow} alt="arrow" className={styles.btnArrow}></img>
                                        </div>
    
                                    </div>
                                    <div className={styles.describe}>
                                        <p className={styles.p1} >{service.title}</p>
                                        <p className={styles.p2}>{service.description}</p>
                                    </div>
                                </div>
                                <DoctorDetail id={service.id} title={service.title}></DoctorDetail>
                            </div>
                        </div>
                    ) 
                })} 
                <DrDropinModal isOpen={showDrDropinModal} closeModal={closeModal} companyCode={companyCode}></DrDropinModal>
            </div>
        )
    }
    else {
        return <p></p>
    }

}
export default withTranslation()(Cards);
