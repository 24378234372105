import React from 'react';
import styles from '../SnapmedModal/SnapmedModal.module.css';
import icon from '../../../assets/images/icon.svg';
import { fetchServiceDoctors } from '../../../api/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import logo from '../../../assets/images/logo.svg';
import Snaplogo from '../../../assets/images/snapmed_logo.svg';
import arrow from '../../../assets/images/modal_arrow.svg';

const SnapmedModal = ({...props}) => {
    const {t} = props;

    return <div id="snapModal" className={styles.mainContainer} style={{display:props.setDisplay}}>
        <p className={styles.modalText}>{parse(t("Please wait, while we redirect you to our partner’s page.."))}</p>
        <div className={styles.modalRow}>
            <img className={styles.auroraLogo} src={logo}></img>
            <img className={styles.arrowImg} src={arrow}></img>
            <img className={styles.snapLogo} src={Snaplogo}></img>
        </div>
    </div> ;

}

export default withTranslation()(SnapmedModal);