import React from 'react';
import styles from './SideBox.module.css';
import BFman1 from '../../../assets/images/BFman1.svg';
// import { parse } from 'dotenv/types';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";

const SideBox = ({...props}) => {
    const {t}= props;
    return (
        <div className={styles.mainContainer}>
            <div className={styles.try}>
                <p className={styles.header}>{parse(t("Book your appointment to armonize your Mind & Body"))}</p>
                <div className={styles.mainRow}>
                    <div className={styles.step}>
                        <p className={styles.p1}>1</p>
                        <p className={styles.p2}>{parse(t("Book a Consultation"))}</p>
                    </div>
                    <div className={styles.step}>
                        <p className={styles.p1}>2</p>
                        <p className={styles.p2}>{parse(t("Click Video Link in SMS"))}</p>
                    </div>
                    <div className={styles.step}>
                        <p className={styles.p1}>3</p>
                        <p className={styles.p2}>{parse(t("Meet Doctors Online"))}</p>
                    </div>
                </div>
                <img src={BFman1} alt="doctor" className={styles.docImg}></img>
            </div>
        </div>
    )
}


export default withTranslation()(SideBox);
