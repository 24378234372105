import React, { useEffect } from 'react';
import styles from '../Header/Header.module.css';
import man from '../../../assets/images/man.svg';
import clock from '../../../assets/images/clock.svg';
import cam from '../../../assets/images/cam.svg';
import arrow from '../../../assets/images/arrow_blue.svg';
import { fetchUpcomingAppointments } from '../../../api/index';
import { useState } from 'react';
import moment from 'moment';
import logo from '../../../assets/images/logo.svg';
import logOut from '../../../assets/images/logOut.svg';
import auth from '../../Auth/auth';
import { useHistory } from 'react-router';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import leftArrow from '../../../assets/images/leftArrow.svg';
import 'moment/locale/nb'

const Header = ({...props }) => {
    const {t} = props;
    const [appointments, setAppointments] = useState();
    const [viewAll, setViewAll] = useState("none");
    const [viewLess, setViewLess] = useState("flex");
    const [opa, setOpa] = useState("hello");
    const history = useHistory();
    

    useEffect(() => {
        const getAppointment = async () => {
            const response = await fetchUpcomingAppointments();
            setAppointments(response);  
        }
        getAppointment();
        //setOpa("1");
    }, [])


  
    const signOut = () => {
        auth.logout(() => {
          history.push("/login");
        });
      };

      const getDate = (timestamp) => {
        const day = moment(timestamp).calendar({
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          lastWeek: "[Last] dddd",
          nextWeek: "[Next] dddd",
          sameElse: "L",
        });
    
        const no_date_from = moment(timestamp).locale("nb"); //norwegian date
        //console.log(no_date_from);
        // if (day == "Today" || day == "Tomorrow") {
        //   return day + moment(timestamp).format(", Do MMM YYYY");
        // }
       return no_date_from.format("DD.MMM HH:mm  ");
      };

    const clickHandler = () => {
        //Expand Panel
        setViewAll("flex");
        document.querySelector("#header").style.filter = "blur(3px)";
        document.querySelector("#cards").style.filter = "blur(3px)";
    }
    const clickHandlerLess = () => {
        //Squeeze Panel
        setViewAll("none")
        document.querySelector("#header").style.filter = "blur(0px)";
        document.querySelector("#cards").style.filter = "blur(0px)";
        //setViewLess("flex")
    }

    const appointmentBlock = (toDisplay) => {
        //If only two appointments
        let app = [];
        for (let i = 0; i < appointments.length; i++) {
            let v = getDate(appointments[i].booked_from);
            let confrere_url = appointments[i].resource_id.confrere_url;
            let firstChar = (appointments[i].resource_id.name).charAt(0);
            if (toDisplay === "onlyTwo" && i === 2)
                break;
            app.push(
                <div className={styles.subDocDiv} >
                    <img src={appointments[i].resource_id.image==null ? `https://aurorahelse.s3.eu-north-1.amazonaws.com/public/Alphabetic_Placeholders/${firstChar}.png` : appointments[i].resource_id.image} alt="doctor" className={styles.docExtraImage1}></img>
                    <div className={styles.appDiv}>
                        <p className={styles.appDetail} >{appointments[i].service_id.title} : {appointments[i].resource_id.name}</p>
                        <div className={styles.time}>
                            <img src={clock} alt="clock" className={styles.callImg}></img>
                            <p className={styles.appDetail} >{v}</p>
                        </div>
                    </div>
                    <a href={confrere_url}><div className={styles.callIcon}>
                        <img src={cam} alt="doctor" className={styles.docExtraImage}></img>
                    </div></a>
                </div>
            )
        }

        return app;
    }
    const appointmentBlockAll = (toDisplay) => {
        //if more than two appointments
        let app = [];

        for (let i = 0; i < appointments.length; i++) {
            let v = getDate(appointments[i].booked_from);
            if (toDisplay === "onlyTwo" && i === 2)
                break;
            app.push(
                <div className={styles.subDocDivAll}>
                    <img src={appointments[i].resource_id.image} alt="doctor" className={styles.docExtraImage2}></img>
                    <div className={styles.appDiv}>
                        <p >{appointments[i].service_id.title} : {appointments[i].resource_id.name}</p>
                        <div className={styles.time}>
                            <img src={clock} className={styles.docExtraImage} alt="doctor"></img>
                            <p>{v}</p>
                        </div>
                    </div>
                    <div className={styles.callIcon1}>
                        <img src={cam} className={styles.docExtraImage} alt="doctor"></img>
                    </div>
                </div>
            )
        }

        return app;
    }

    return (
        <React.Fragment>
            <div className={styles.mainDiv}>
                <div className={styles.nav}>
                    <img src={logo} alt="fig" className={styles.navImage}></img>
                    <div className={styles.plan}>AURORA {props.subscription == "basis-light"?`Basis light - kun lege`:props.subscription}</div>
                    <img src={logOut} alt="out" onClick={signOut}></img>

                </div>
                <div id="header" className={styles.headerDiv}>

                    <div className={styles.imageDiv}>
                        <img src={man} className={styles.doctor} alt="doctor"></img>
                        <div className={styles.subHead1}>
                            <p className={styles.subHead1Para}>{parse(t("One place for all your Health and Wellness needs"))}</p>
                        </div>
                    </div>
                    <div className={styles.containDiv}  >

                        <div id="appointment1" className={styles.docDiv} styles={{ display: viewLess }}  >
                            <p className={styles.notifications}>{parse(t("Notifications"))}</p>
                            
                            {appointments && appointments.length>0? appointmentBlock("onlyTwo") :<div className={styles.notifyDiv}>
                                <p>{parse(t("There are no upcoming appointments"))}</p>
                                </div>}
                            <div className={styles.viewAll} style={{opacity:appointments && appointments.length>0?"1":"0.4", pointerEvents:appointments && appointments.length>0?"auto":"none"}} >
                            <a href="#" onClick={() => clickHandler()}>{parse(t("View All"))}</a> 
                            <img src={arrow} className={styles.btnArrow} alt="arrow"></img>
                            </div>
                        </div> 
                    </div>

                </div>

                {appointments ? <div id="expand" className={styles.displayAll} style={{ display: viewAll, opacity: opa }}>
                    <p className={styles.notifications}>{parse(t("All Notifications"))} ({appointments.length})</p>
                    {/* <a href="#" onClick={() => clickHandlerLess()} style={{ textDecoration: 'none', color: '', marginLeft: 'px', }}> <span><i className="fas fa-chevron-left" style={{ marginRight: '10px' }}></i>Back</span></a> */}
                    <div className={styles.cardBtn1} onClick={()=>clickHandlerLess()}  >
                                <img className={styles.btnArrow} alt="arrow" src={leftArrow}></img>
                                <p className={styles.back}>{parse(t("Back"))}</p>
                            </div>
                    {appointments ? appointmentBlockAll("all") : <p>{parse(t("Loading..."))}</p>}
                </div> : <p>{parse(t("Loading..."))}</p>}
            </div>
        </React.Fragment>
    )
};

export default withTranslation()(Header);