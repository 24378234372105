import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import styles from './DrDropinModal.module.css';
import heartIcon from '../../assets/images/heart_logo_big.svg';
import step1 from '../../assets/images/step1.svg';
import step2 from '../../assets/images/step2.svg';
import step3 from '../../assets/images/step3.svg';

const DrDropinModal = ({...props}) => {
    const {t} = props;
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.closeModal} className={styles.modalStyles} overlayClassName={styles.modalOverlay}>
                <div className={styles.modalHeader}>
                    <div className={styles.logo}>
                        <img src={heartIcon} alt="" />
                    </div>
                    <div>
                        <div className={styles.header}>{t("dr_dropin_header")}</div>
                        <div className={styles.subHeader}>{t("dr_dropin_sub_header")}</div>
                    </div>
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.stepper}>
                        <div className={styles.bodyHeader}>{t("step_1")}</div>
                        <div className={styles.downloadLink}>{t("download_link")}</div>
                        <img className={styles.imgMaxWidth} src={step1} alt="" />
                        <span>:</span>
                        <a target='_blank' href='https://drdropin.no/no/video'><span>https://drdropin.no/no/video</span></a>
                    </div>
                    <div className={styles.stepper}>
                        <div className={styles.bodyHeader}>{t("step_2", { companyCode: props.companyCode })}</div>
                        <div className={styles.appScreen}>{t("app_screen")}</div>
                        <img src={step2} alt="" />
                    </div>
                    <div className={styles.stepper}>
                        <div className={styles.bodyHeader}>{t("step_3")}</div>
                        <div className={styles.appScreen}>{t("app_screen")}</div>
                        <img className={styles.imgPadLeft} src={step3} alt="" />

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default withTranslation()(DrDropinModal);