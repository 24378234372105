import React from 'react'
import SubHeader from './SubHeader/SubHeader';
import logo from '../../assets/images/logo.svg';
import logOut from '../../assets/images/logOut.svg';
import styles from '../Appointment/BookingSteps.module.css'
import SideBox from './SideBox/SideBox';
import Verification from './Verification/Verfication';
import Stepper from './Stepper/Stepper';
import { useState } from 'react';
import arrow from '../../assets/images/arrow.svg';
import leftArrow from '../../assets/images/leftArrow.svg';
import moment from 'moment';
import { bookAppointment } from '../../api/index';
import { useHistory } from 'react-router';
import auth from '../Auth/auth';
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import Modal from '../NewDashboard/ServiceModal/Modal';
import TimeSlotAlreadyBookedModal from './TimeSlotAlreadyBookedModal';
import { useEffect } from 'react';
import singletonCleverTapInstance from '../../analytics/clevertap';

const BookingSteps = ({ ...props }) => {
    const { t } = props;
    const [activeStep, setActiveStep] = useState(1);
    const [doc, setDoc] = useState();
    const [date, setDate] = useState();
    const [time, setTime] = useState();
    const [note, setNote] = useState();
    const [nextDisable, setNextDisable] = useState("true")
    const [phone, setPhone] = useState();
    const [SSN, setSSN] = useState();
    const [disableBA, setDisableBA] = useState("true");
    const [viewModal, setViewModal] = useState("none");
    const [showTimeSlotAlreadyBookedModal, setShowTimeSlotAlreadyBookedModal] = useState(false);


    const history = useHistory();

    const handleNext = () => {
        singletonCleverTapInstance.selectTimeSlot(props.location.state.title);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        if (activeStep === 1) {
            history.push('/dashboard');
        }

        setNextDisable("true")
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /* const getNotes = (value) => {
        setNote(value);
    } */
    const signOut = () => {
        auth.logout(() => {
            history.push("/login");
        });
    };

    const getVerificationDetails = (value1, value2) => {
        setPhone(value1);
        if (value2 === "true") {
            setDisableBA("false")
        }
        else {
            setDisableBA("true")
        }
    }

    //Get Selected Doctor, Date and Time
    const getData = async (value1, value2, value3) => {
        setDoc(value1)
        setDate(value2);
        setTime(value3);
        if (value3 && value2) {

            if (value1 && value2 && value3) {
                setNextDisable("false")
            }
            else {
                setNextDisable("true")
            }
        }

    }


    const createAppointment = async () => {
        // const a = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
        const start = ((moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).concat("T" + time.timestamp + ":00+00:00"));
        const end = ((moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).concat("T" + time.timestamp_end + ":00+00:00"));
        const data = {
            "service_id": props.location.state.service_id.id,
            "resource_id": doc.id,
            "resource_name": doc.name,
            "timestamp": start,
            "timestamp_end": end,
            "notes": note ? props.location.state.subscription + " / " + note 
            : props.location.state.subscription + " / ",
            "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const response = await bookAppointment(data);
        const service = props.location.state.title;
        if(response === "timeslot_booked"){
            toggleTimeSlotAlreadyBookedModalDisplay();
        }
        else if(response === "Something went wrong"){
            window.location.reload();
        }
        else{
            singletonCleverTapInstance.bookedService(service);
            history.push({ pathname: './summary', state: { doctor: doc, selectedDate: { date }, selectedTime: { time }, notes: { note }, service: { service } } })
        }
    }

    const toggleTimeSlotAlreadyBookedModalDisplay = () =>{
        if(showTimeSlotAlreadyBookedModal === true){
            setShowTimeSlotAlreadyBookedModal(false);
            window.location.reload()
        }
        else{
            setShowTimeSlotAlreadyBookedModal(true);
        }
    }


    return (
        <React.Fragment>
            <TimeSlotAlreadyBookedModal showModal = {showTimeSlotAlreadyBookedModal} service_id={props.location.state.service_id} closeModal = {()=> toggleTimeSlotAlreadyBookedModalDisplay()}></TimeSlotAlreadyBookedModal>
            {viewModal === "flex" ? <Modal title={props.location.state.title}></Modal> : <p style={{ display: 'none' }}>hello</p>}
            <div id="book" className={styles.coverDiv} >
                <div className={styles.nav} style={{ backgroundColor: "white" }}>
                    <a><img src={logo} alt="logo" onClick={() => history.push('/dashboard')}></img></a>
                    {/* <button className={styles.navBtn}>Logout</button> */}
                    <img src={logOut} alt="logo" onClick={signOut}></img>
                </div>
                <div className={styles.mainContainer}>
                    <SubHeader service_id={props.location.state.service_id}></SubHeader>

                    <div className={styles.container}>

                        <SideBox id="side"></SideBox>

                        <div className={styles.midContainer}>

                            {activeStep === 1 ? <Stepper finalDoc={getData} service_id={props.location.state.service_id}></Stepper> : <Verification finalVerify={getVerificationDetails} ></Verification>
                                /* activeStep === 2 ? <AppointmentSummary></AppointmentSummary>: */
                            }
                            {activeStep === 1 ?
                                <div className={styles.buttonDiv}>
                                    <div className={styles.cardBtn1} onClick={handleBack} >
                                        <img className={styles.btnArrow} alt="arrow" src={leftArrow}></img>
                                        <p className={styles.back}>{parse(t("Back"))}</p>
                                    </div>
                                    <p style={{ display: time ? "block" : "none" }} className={styles.almost}>{parse(t("Almost There, Just proceed to mobile verification"))}</p>
                                    <button className={styles.cardBtn} onClick={handleNext} style={{ opacity: nextDisable === "true" ? 0.4 : 1, pointerEvents: nextDisable === "true" ? "none" : "auto" }}>
                                        <p className={styles.next}>{parse(t("Next"))}</p>
                                        <img className={styles.btnArrow} alt="arrow" src={arrow}></img>
                                    </button>
                                    {/* <button onClick={handleNext} disabled={nextDisable} style={{ fontSize: '16px', width:'200px' }} className={styles.cardBtn}>{nextDisable}<span> <i style={{ marginLeft: '10px' }} className="fas fa-chevron-right"></i></span></button> */}
                                </div> :
                                <div className={styles.buttonDiv}>
                                    <div className={styles.cardBtn1} onClick={handleBack} >
                                        <img className={styles.btnArrow} alt="arrow" src={leftArrow}></img>
                                        <p className={styles.back}>{parse(t("Back"))}</p>
                                    </div>

                                    <button className={styles.cardBtn2} onClick={createAppointment} style={{ opacity: disableBA === "true" ? 0.4 : 1, pointerEvents: disableBA === "true" ? "none" : "auto" }}>
                                        <p className={styles.next}>{parse(t("Book Appointment"))}</p>
                                        <img className={styles.btnArrow} alt="arrow" src={arrow}></img>
                                    </button>

                                    {/* <button onClick={createAppointment} style={{ fontSize: '16px', width:'185px' }} className={styles.cardBtn}>Book Appointment<span> <i style={{ marginLeft: '10px' }} className="fas fa-chevron-right"></i></span></button> */}
                                </div>}

                        </div>


                    </div>

                </div>

            </div>

        </React.Fragment>
    )
}

export default withTranslation()(BookingSteps);