import React from 'react';
import styles from '../Cards/Cards.module.css';
import styles1 from '../CardDoctors/CardDoctors.module.css';
import { useEffect,useState } from 'react';
import { fetchServiceDoctors } from '../../../api/index';
import { withTranslation } from "react-i18next";

const CardDoctors = ({ ...props }) => {


    const[doctor,setDoctor] = useState();
    const [drDropIconUrl, setDrDropIconUrl] = useState(null);
    useEffect(()=>{
        const getDoctor = async () => {
            switch (props.id) {
                case 6:
                    setDrDropIconUrl('https://aurorahelse.s3.eu-north-1.amazonaws.com/public/snapmed_logo.svg');
                    break;
                case 7:
                    setDrDropIconUrl('https://aurorahelse.s3.eu-north-1.amazonaws.com/public/logo_dr_dropin.svg');
                    break;
                default:
                    const response = await fetchServiceDoctors(props.id);
                    if (response && response.data && response.data.length>0) {
                        setDoctor(response.data);
                    }
                    break;
            }
        }
       getDoctor();
    },[])


    const additionalDoctors =()=>{
        let doc=[];
        let i=1;
        let k =doctor.length-1;
        let j;
        for(i=1;i<doctor.length;i++,k--){
             j = 10*k-10;
            let firstChar = (doctor[i].name).charAt(0);
            doc.push(
                <img key={`doctor ${i}`} src={doctor[i].image==null ? `https://aurorahelse.s3.eu-north-1.amazonaws.com/public/Alphabetic_Placeholders/${firstChar}.png` : doctor[i].image } alt="doctor" className={styles1.docExtraImage1} style={{ zIndex: `${i}`, position:"relative", left:`${j}%` }}></img>    
            )
            
        }
        
        if(doctor.length > 1){
            return <div className={styles1.addDocExtra}>
             <div className={styles1.addDoc}>
             {doc}
             </div>
               {/* { <p>+{doctor.length-1} {doctor.length==2?parse(t("Doctor")):parse(t("Doctors"))}</p>} */}
               { <p /* style={{position:'relative',left:`${j}%`}} */>+{doctor.length-1}</p>}
         </div> 
        }
      
    }
    
    if(doctor){
        let firstChar = (doctor[0].name).charAt(0);
    return (
        <div className={styles.docDiv}>
            <img src={doctor[0].image == null ? `https://aurorahelse.s3.eu-north-1.amazonaws.com/public/Alphabetic_Placeholders/${firstChar}.png` : doctor[0].image} alt="doctor" className={styles1.docImage}></img>
            <div className={styles1.detailDoc}>
                <p className={styles1.p1}>{doctor[0].name}</p>
                <p className={styles1.p2}>{doctor[0].description}</p>
            </div>
            
                {additionalDoctors()}
            
        </div>
    )}
    else if (drDropIconUrl) {
        return (
            <div className={styles.docDiv}>
                <img src={drDropIconUrl} alt='icon' className={styles1.docImage}></img>
                <div className={styles1.detailDoc}>
                    <p className={styles1.p2}>Powered by</p>
                    <p className={styles1.p1}>{ props.id === 6? "Snapmed":"Dr. Dropin"}</p>
                </div>
            </div>
        )
    } else return (
        <div className={styles.docDiv}></div>
    )
}

export default withTranslation()(CardDoctors);
