import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import LoginButton from "./LoginButton/LoginButton";
import { fetchUser, sendMagicLink } from "../../api";
import { useToasts } from "react-toast-notifications";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import auth from "../Auth/auth";
import { useHistory } from "react-router-dom";
import logo from '../../assets/images/logo.svg';
import illustrations from '../../assets/images/login_illustrations1.svg';

const Login = (props) => {
  const [emailId, setEmailId] = useState("");
  const [user, setUser] = useState({});
  const [emailResponse, setEmailResponse] = useState();
  //let history = useHistory();
  const { t } = props;
  const { addToast } = useToasts();
  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        const isLoggedIn = await auth.isAuthenticated();
        if (isLoggedIn) {
          props.history.push("/dashboard");
        }
      } catch (err) {
        console.log(err);
      }
    };
    isUserLoggedIn();
  }, []);


  async function validate(e) {
    if (e.keyCode !== 13) {
      return;
    }

    await login();
    //validation of the input...
  }

  const login = async () => {
    let emailField = document.getElementById("email");
    let emailId = emailField.value;

    if (!emailId || emailId === "") {
      return;
    }
    try {
      // check whether user is there in the db
      const response = await fetchUser(emailId);
      //  setting the state now...since response is having user details..
      setUser(response);
      console.log(response)
      // setting the emailId
      setEmailId(emailId);
      if(response.email_status === "ACCEPTED")
      await sendMagicLink(emailId);
      // emailField.placeholder = "";
      setEmailResponse(response.email_status);
    } catch (error) {
      setEmailId(emailId);
      if (error && error.response) {
        setEmailResponse(error.response.data.message);
        /* addToast(error.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        }); */
      } else {
        setEmailResponse("Something went wrong");
        /* addToast("Something went wrong", {
          appearance: "error",
          autoDismiss: true,
        }); */
      }
    } finally {
      emailField.value = "";
    }

    // login here...
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.subContainer1}>
          <div className={styles.midContainer}>
            <img className={styles.logoImg} alt="logo" src={logo}></img>
            <div>
              <img src={illustrations} alt="illustration"></img>
              <p className={styles.panelText}>{t("One place for all your Health and Wellness needs")}</p>
            </div>
          </div>
        </div>
        <div className={styles.vr}></div>
        <div className={styles.subContainer2}>
          <div className={styles.midContainer}>
            <div className={styles.welcomeDiv}>
              <p className={styles.welcomeText}>{t("Welcome to your Aurora Healthcare Account")}</p>
              <LoginButton
                emailResponse={emailResponse}
                functions={{ validate, login }}
                email={emailId}
              ></LoginButton>
            </div>
            <div className={styles.footer}>
              <a href="https://www.aurorahelse.com/terms-and-conditions" >{t("Terms of Use")}</a>
              <div className={styles.divider}>
                <a href="https://www.aurorahelse.com/privacy-policy" >{t("Privacy Policy")}</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );


};
Login.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withTranslation()(Login);
